import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Tab, Tabs } from '@material-ui/core';

import { colors } from '../../palette';

// ------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  flexContainer: {
    gap: '1rem',
  },
  indicator: {
    bottom: 8,
  },
  tabRoot: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'none',
    color: colors.neutral060,
    width: 'fit-content',
    minWidth: 60,
    padding: 0,
    '&:hover': {
      color: colors.blue060,
    },
  },
  selectedTab: {
    color: colors.blue060,
  },
}));

function FtrDivider(props) {
  return (
    <div
      style={{
        borderBottom: `1px solid ${colors.neutral030}`,
        position: 'absolute',
        bottom: 8,
        left: 0,
        right: 0,
        ...(props.style || {}),
      }}
    />
  );
}

function FtrTabs(props) {
  const classes = useStyles();

  const { value, onChange = () => {}, itemList, tabStyle = {} } = props;

  return (
    <Tabs
      classes={{
        flexContainer: classes.flexContainer,
        indicator: classes.indicator,
      }}
      style={{
        width: 'auto',
        backgroundColor: 'inherit',
        overflow: 'hidden',
      }}
      indicatorColor='primary'
      centered
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      variant='scrollable'
      scrollButtons='auto'
    >
      {itemList?.map((item) => {
        return (
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab,
            }}
            style={{
              ...tabStyle,
            }}
            key={item.key}
            label={item.text}
            disabled={item.disabled}
          />
        );
      })}
    </Tabs>
  );
}

FtrTabs.FtrDivider = FtrDivider;

export default FtrTabs;
