import React, { useEffect, useMemo } from 'react';

import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import FilesUploadButton from '../../components/FilesUploadButton';
import { FtrButton, FtrTextField } from '../../components/ftr-components';
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
  FlexRowCenter,
} from '../../components/layouts/FlexLayouts';
import AutoBalloonPresentational from './AutoBalloonPresentational';

import withLoadingBackDropTextHOC from '../../hocs/withLoadingBackDropTextHOC';

import { getItemDetailsApi } from '../../apis/itemApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { isPdfFile } from '../../utils/fileUtils';
import { getFileNameFromCadFile } from '../../utils/itemUtils';

import useItemAutoBalloon from '../../hooks/useItemAutoBalloonHook';

import { notifyError, notifySuccess } from '../../services/notificationService';
import { uploadFileToS3 } from '../../services/s3Service';

import { AUTO_BALLOONING_S3_FOLDER } from '../../constants/s3Constants';

// -------------------------------------------------------------------------------------------------

const SELECT_OPTIONS = {
  ITEMS: 'ITEMS',
  PDF_URL: 'PDF_URL',
};

function AutoBalloon(props) {
  const { setLoadingMessage = () => {} } = props;

  const [selectedOption, setSelectedOption] = React.useState(
    SELECT_OPTIONS.PDF_URL
  );

  const [pdfUrl, setPdfUrl] = React.useState(
    'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/Public/TEST_TURN_V2_1734922093875.pdf'
  );

  const [itemID, setItemID] = React.useState(null);
  const [itemInfo, setItemInfo] = React.useState(null);
  const [loadingItemInfo, setLoadingItemInfo] = React.useState(false);
  const [selectedTechnicalDrawingFile, setSelectedTechnicalDrawingFile] =
    React.useState(null);

  const {
    autoBallooning,
    downloadingPdf,
    deletingBalloon,
    verifying,
    saving,
    downloadingReport,
    originalImageUrls,
    annotatedImageUrls,
    annotationData,
    totalPages,
    setDownloadingReport,
    setAnnotationData,
    generateAutoBallonForItem,
    addManualBalloons,
    deleteBalloon,
    downloadBalloonsReportCsv,
    downloadBalloonedPdf,
  } = useItemAutoBalloon();

  const technicalDrawings = useMemo(() => {
    if (isEmptyValue(itemInfo)) {
      return [];
    }

    const originalFiles = isEmptyValue(itemInfo.originalFiles)
      ? []
      : itemInfo.originalFiles?.split(',');

    const cadFile = isEmptyValue(itemInfo.cadFile)
      ? []
      : itemInfo.cadFile?.split(',');

    const _techFiles = [...cadFile, ...originalFiles]?.filter((fileUrl) =>
      isPdfFile(fileUrl)
    );

    if (!isEmptyValue(_techFiles)) {
      setSelectedTechnicalDrawingFile(_techFiles[0]);
    }

    return _techFiles;
  }, [itemInfo]);

  const enableAutoBalloonButton =
    (selectedOption === SELECT_OPTIONS.PDF_URL && !isEmptyValue(pdfUrl)) ||
    (selectedOption === SELECT_OPTIONS.ITEMS &&
      !isEmptyValue(selectedTechnicalDrawingFile));

  useEffect(() => {
    if (deletingBalloon === true) {
      setLoadingMessage('Deleting balloon...');
      return;
    }
    setLoadingMessage('');
  }, [deletingBalloon]);

  useEffect(() => {
    if (verifying === true) {
      setLoadingMessage('Verify updating in progress...');
      return;
    }
    setLoadingMessage('');
  }, [verifying]);

  useEffect(() => {
    if (saving === true) {
      setLoadingMessage('Saving...');
      return;
    }
    setLoadingMessage('');
  }, [saving]);

  // -----------------------------------------------------------------------------------------------

  const handleAutoBalloon = () => {
    const fileUrl =
      selectedOption === SELECT_OPTIONS.PDF_URL
        ? pdfUrl
        : selectedOption === SELECT_OPTIONS.ITEMS
          ? selectedTechnicalDrawingFile
          : null;

    if (isEmptyValue(fileUrl)) {
      return;
    }

    generateAutoBallonForItem({ pdfUrl: fileUrl, itemID }).catch(() => {
      notifyError('Auto balloon failed');
    });
  };

  const handleAddBalloons = async (newBalloon) => {
    if (isEmptyValue(newBalloon)) {
      return;
    }

    setLoadingMessage('Adding balloon...');
    await addManualBalloons(newBalloon)
      .then(() => {
        notifySuccess('Balloon added successfully');
        setLoadingMessage('');
      })
      .catch((err) => {
        notifyError('Add balloon failed');
        setLoadingMessage('');
        throw err;
      });
  };

  const handleDeleteBalloon = (data) => {
    const balloonNumber = data?.number;
    if (isEmptyValue(balloonNumber)) {
      return;
    }

    deleteBalloon(balloonNumber);
  };

  const handleDownloadReportCsv = () => {
    downloadBalloonsReportCsv()
      .then(() => setDownloadingReport(false))
      .catch(() => {
        setDownloadingReport(false);
        notifyError('Download report csv failed');
      });
  };

  const handleDownloadPdf = async () => {
    await downloadBalloonedPdf().catch(() => {
      notifyError('Download pdf failed');
    });
  };

  const handleUploadPdfFile = async (files) => {
    const file = files[0];
    const fileName = file.name;
    const data = await uploadFileToS3(
      file,
      `${AUTO_BALLOONING_S3_FOLDER}/${fileName}`,
      fileName
    );

    const { s3ObjectUrl } = data || {};

    if (isEmptyValue(s3ObjectUrl)) {
      notifyError('Upload pdf failed');
      return;
    }

    setPdfUrl(decodeURIComponent(s3ObjectUrl));
  };

  const handleLoadItemInfo = () => {
    if (isEmptyValue(itemID)) {
      return;
    }

    setLoadingItemInfo(true);
    getItemDetailsApi(itemID)
      .then((info) => {
        setItemInfo(info);
        setLoadingItemInfo(false);
      })
      .catch((err) => {
        setLoadingItemInfo(false);
        notifyError(err.message || 'Load item info failed');
      });
  };

  return (
    <FlexColumn
      style={{
        margin: '2rem 0',
        padding: '0 2rem',
        boxSizing: 'border-box',
        gap: '1rem',
        marginBottom: '1rem',
      }}
    >
      {renderSelectOptions()}
      {selectedOption === SELECT_OPTIONS.PDF_URL && renderPdfUrlField()}
      {selectedOption === SELECT_OPTIONS.ITEMS && renderItemSelection()}
      {selectedOption === SELECT_OPTIONS.PDF_URL && (
        <FlexRowCenter style={{ gap: '1rem' }}>
          {renderAutoBalloonButton()}
        </FlexRowCenter>
      )}
      <AutoBalloonPresentational
        autoBallooning={autoBallooning}
        originalImageUrls={originalImageUrls}
        annotationData={annotationData}
        totalPages={totalPages}
        annotatedImageUrls={annotatedImageUrls}
        handleDeleteBalloon={handleDeleteBalloon}
        addManualBalloons={handleAddBalloons}
        setAnnotationData={setAnnotationData}
      />
      <FlexRowCenter
        style={{ marginTop: '2rem', gap: '1rem', boxSizing: 'border-box' }}
      >
        {!isEmptyValue(annotationData) && (
          <FtrButton
            color='blue'
            variant='outlined'
            onClick={handleDownloadReportCsv}
            disabled={downloadingReport}
            loading={downloadingReport}
          >
            Download Report CSV
          </FtrButton>
        )}
        {!isEmptyValue(annotatedImageUrls) && (
          <FtrButton
            color='blue'
            variant='outlined'
            onClick={handleDownloadPdf}
            disabled={downloadingPdf}
            loading={downloadingPdf}
          >
            Download PDF
          </FtrButton>
        )}
      </FlexRowCenter>
    </FlexColumn>
  );

  function renderAutoBalloonButton() {
    return (
      <FtrButton
        color='blue'
        onClick={handleAutoBalloon}
        disabled={!enableAutoBalloonButton || autoBallooning}
        loading={autoBallooning}
        style={{ minWidth: 150 }}
      >
        Auto Balloon
      </FtrButton>
    );
  }

  function renderItemSelection() {
    return (
      <FlexColumnCenter style={{ width: 1000, maxWidth: 1000, margin: 'auto' }}>
        <FlexRowCenter
          style={{
            width: '100%',
            margin: 'auto',
            boxSizing: 'border-box',
          }}
        >
          <FlexRow style={{ flexGrow: 1 }}>
            <FtrTextField
              label='Item ID'
              value={itemID}
              onChange={(e) => setItemID(e.target.value)}
              fullWidth
              style={{
                marginBottom: 0,
              }}
            />
            <FtrButton
              size='small'
              color='blue'
              variant='outlined'
              onClick={handleLoadItemInfo}
              disabled={isEmptyValue(itemID) || loadingItemInfo}
              loading={loadingItemInfo}
            >
              Load Item Info
            </FtrButton>
          </FlexRow>
        </FlexRowCenter>
        {!isEmptyValue(technicalDrawings) && (
          <FlexRow
            style={{ width: '100%', margin: 'auto', boxSizing: 'border-box' }}
          >
            <TextField
              id='select-technical-drawing'
              select
              label='Technical Drawing Files'
              value={selectedTechnicalDrawingFile}
              onChange={(evt) =>
                setSelectedTechnicalDrawingFile(evt.target.value)
              }
              fullWidth
              variant='outlined'
              margin='dense'
              style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            >
              {technicalDrawings?.map((url, index) => {
                const encodedFileName = url?.split('/').pop();
                const fileName = getFileNameFromCadFile(encodedFileName);
                return (
                  <MenuItem key={index} value={url}>
                    {fileName}
                  </MenuItem>
                );
              })}
            </TextField>
            {renderAutoBalloonButton()}
          </FlexRow>
        )}
      </FlexColumnCenter>
    );
  }

  function renderPdfUrlField() {
    return (
      <FlexRowCenter
        style={{
          width: '100%',
          margin: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <FlexRow style={{ flexGrow: 1 }}>
          <FtrTextField
            label='PDF URL'
            value={pdfUrl}
            onChange={(e) => setPdfUrl(e.target.value)}
            fullWidth
            style={{
              marginBottom: 0,
            }}
          />
        </FlexRow>
        <FilesUploadButton
          buttonText='Upload PDF'
          handleUploadFiles={handleUploadPdfFile}
          multiple={false}
          accept='.pdf'
        />
      </FlexRowCenter>
    );
  }

  function renderSelectOptions() {
    return (
      <FormControl component='fieldset'>
        <RadioGroup
          row
          value={selectedOption}
          onChange={(event) => setSelectedOption(event.target.value)}
        >
          <FormControlLabel
            value={SELECT_OPTIONS.PDF_URL}
            control={<Radio color='primary' />}
            label='PDF Url'
          />
          <FormControlLabel
            value={SELECT_OPTIONS.ITEMS}
            control={<Radio color='primary' />}
            label='Items'
          />
        </RadioGroup>
      </FormControl>
    );
  }
}

export default withLoadingBackDropTextHOC(AutoBalloon);
