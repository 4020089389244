import { format } from 'date-fns';
import { CsvBuilder } from 'filefy';
import { ceil } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { Button, Link as LinkButton, Tooltip } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';

import { Lens as LensIcon } from '@material-ui/icons';

import InstantLogInfoPopup from '../../components/popups/InstantLogInfoPopup';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { FlexColumn } from '../../components/layouts/FlexLayouts';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import { getAllPPEPriceQuoteFeedback } from '../../apis/ppeFeedbackApi';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { generateCurrentCustomDateTimeString } from '../../util';
import { isEmptyValue } from '../../utils/commonUtils';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(2),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
  seeMoreButton: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

const ROW_HEIGHT = 100;

function PpePriceFeedbackDataGridPagination() {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const [tableData, setTableData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [filteredData, { setSearchStr, setColumnsDef, setSourceData }] =
    useDataGridFilterHook({
      search: '',
      source: tableData,
    });

  const [instantLogInfoPopup, updateInstantLogInfoPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );

  const getTableData = () => {
    updateTableQueryParams({ loading: true });
    getAllPPEPriceQuoteFeedback()
      .then((data) => {
        setTableData(data);
      })
      .catch((e) => console.log(e))
      .finally(() => updateTableQueryParams({ loading: false }));
  };

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(tableData);
  }, [tableData]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const defaultColumns = [
    {
      headerName: 'Unseen',
      field: 'hasSeen',
      renderCell: ({ row: rowData }) => {
        if (rowData.hasSeen !== 0) {
          return '';
        }
        return (
          <Tooltip title='Unseen log'>
            <LensIcon color='error' fontSize='small' />
          </Tooltip>
        );
      },
      width: 75,
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      width: 150,
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={format(new Date(rowData.createdAt), 'yyyy-MM-dd HH:mm')}
          />
        );
      },
    },
    {
      headerName: 'Item ID',
      field: 'itemID',
      renderCell: ({ row: rowData }) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Tooltip title='Hold Ctrl/Cmd and click to edit in new tab'>
              <Button
                className={classes.linkButton}
                href={`/item/edit/${rowData.itemID}`}
              >
                {rowData.itemID}
              </Button>
            </Tooltip>
          </div>
        );
      },
      width: 80,
    },
    {
      headerName: 'Quotation ID',
      field: 'quotationID',
      renderCell: ({ row: rowData }) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Tooltip title='Hold Ctrl/Cmd and click to edit in new tab'>
              <Button
                className={classes.linkButton}
                href={`/order/edit/${rowData.quotationID}`}
              >
                {rowData.quotationID}
              </Button>
            </Tooltip>
          </div>
        );
      },
      width: 115,
    },
    {
      headerName: 'User (ID)',
      field: 'userID',
      width: 140,
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={`${rowData.userFeedbackName} (${rowData.userID})`}
          />
        );
      },
      valueGetter: ({ row: rowData }) =>
        `${rowData.userFeedbackName} (${rowData.userID})`,
    },
    {
      headerName: 'Role',
      field: 'role',
      width: 100,
    },
    {
      headerName: 'User Email',
      field: 'userFeedbackEmail',
      width: 250,
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.userFeedbackEmail} />
      ),
    },
    {
      headerName: 'Feedback',
      field: 'feedback',
      width: 120,
    },
    {
      headerName: 'Price / Unit (SGD)',
      field: 'PPE Price',
      minWidth: 120,
      flex: 0.5,
      valueGetter: ({ row: rowData }) => {
        const unitPrice = rowData.unitPrice;
        if (!isEmptyValue(unitPrice)) {
          return parseFloat(JSON.stringify(unitPrice));
        }
        let customerPrice = rowData?.meta?.customerPrice;
        const quantity = rowData?.meta?.request?.parameters?.quantity;

        if (typeof customerPrice === 'string') {
          customerPrice = parseFloat(customerPrice);
          if (isNaN(customerPrice)) {
            return customerPrice;
          }
        }
        return parseFloat(JSON.stringify(customerPrice / quantity)) ?? '';
      },
    },
    {
      headerName: 'Target Price / Unit (SGD)',
      field: 'targetPrice',
      minWidth: 120,
      flex: 0.5,
    },
    {
      headerName: 'Feedback Details',
      field: 'feedbackDetails',
      valueGetter: ({ row }) => {
        const { feedbackDetails } = row;
        if (isEmptyValue(feedbackDetails)) {
          return 'N.A.';
        }
        const textArr = Object.entries(feedbackDetails)
          ?.filter(([_key, value]) => value === true)
          ?.map(([key]) => key);
        return textArr?.join(' | ');
      },
      renderCell: ({ row }) => {
        const { feedbackDetails } = row;
        if (isEmptyValue(feedbackDetails)) {
          return 'N.A.';
        }
        const textArr = Object.entries(feedbackDetails)
          ?.filter(([_key, value]) => value === true)
          ?.map(([key]) => key);
        return (
          <FlexColumn
            style={{
              maxHeight: ROW_HEIGHT,
              overflow: 'auto',
              width: '100%',
              padding: '0.5rem',
              boxSizing: 'border-box',
            }}
          >
            {textArr?.map((text) => (
              <DataGridWrapTextCell text={`- ${text}`} key={text} />
            ))}
          </FlexColumn>
        );
      },
      minWidth: 290,
      flex: 0.5,
    },
    {
      headerName: 'Additional Remarks',
      field: 'additionalRemarks',
      valueGetter: ({ row }) => {
        return `${
          isEmptyValue(row.additionalRemarks) ? 'N.A.' : row.additionalRemarks
        }`;
      },
      renderCell: ({ row }) => {
        const text = isEmptyValue(row.additionalRemarks)
          ? 'N.A.'
          : row.additionalRemarks;
        return (
          <DataGridWrapTextCell
            text={text}
            style={{
              maxHeight: ROW_HEIGHT,
              overflow: 'auto',
              width: '100%',
              padding: '0.5rem 0.5rem 0.5rem 0',
              boxSizing: 'border-box',
            }}
          />
        );
      },
      width: 250,
    },
    {
      headerName: 'Request',
      field: 'request',
      renderCell: ({ row: rowData }) => {
        let content = JSON.stringify(rowData.meta.request, null, 2) ?? '';
        const rows = content?.split('\n');
        const length = rows.length;
        content = rows?.slice(0, 4)?.join('\n');
        return (
          <div
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            {content}
            {length > 4 && (
              <LinkButton
                className={classes.seeMoreButton}
                variant='link'
                onClick={() => {
                  updateInstantLogInfoPopup({
                    open: true,
                    selectedData: rowData,
                  });
                }}
              >
                see more...
              </LinkButton>
            )}
          </div>
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.meta.request) ?? '';
      },
      width: 250,
    },
    {
      headerName: 'Response',
      field: 'response',
      renderCell: ({ row: rowData }) => {
        let content = JSON.stringify(rowData.meta.response, null, 2) ?? '';
        const rows = content?.split('\n');
        const length = rows.length;
        content = rows?.slice(0, 4)?.join('\n');
        return (
          <div
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            {content}
            {length > 4 && (
              <LinkButton
                className={classes.seeMoreButton}
                variant='link'
                onClick={() => {
                  updateInstantLogInfoPopup({
                    open: true,
                    selectedData: rowData,
                  });
                }}
              >
                see more...
              </LinkButton>
            )}
          </div>
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.meta.response) ?? '';
      },
      width: 250,
    },
    {
      headerName: 'iqLogsID',
      field: 'iqLogsID',
      width: 300,
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.iqLogsID} />
      ),
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  //Retrieve all ppe feedback when page renders
  useEffect(() => {
    getTableData();
    const _columns = defaultColumns?.filter((col) => !!col);
    setColumns(_columns);
    setColumnsDef(_columns);
  }, []);

  const handleDownloadCsv = async () => {
    const fileName = `All Price Feedback ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          rows={filteredData}
          columns={columns?.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.id + row.userID}
          rowHeight={ROW_HEIGHT}
          headerHeight={80}
          components={{
            Toolbar: getToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({ pageSize: newPageSize })
          }
          rowsPerPageOptions={[10, 20, 50]}
          loading={tableQueryParams.loading}
          disableColumnMenu
          localeText={{
            toolbarExport: 'Export CSV',
          }}
        />
        {instantLogInfoPopup.open && (
          <InstantLogInfoPopup
            open={instantLogInfoPopup.open}
            handleClose={() => updateInstantLogInfoPopup({ open: false })}
            rowData={instantLogInfoPopup.selectedData}
          />
        )}
      </div>
    </div>
  );
}

export default PpePriceFeedbackDataGridPagination;
